import React, { Component } from 'react';
export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>Work</span></h1>
            </div>
            <div className="nine columns main-col">
              {
                resumeData.work && resumeData.work.map((item) => {
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                          <h3>{item.companyName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span> <em className="date">{item.monthOfLeaving} {item.yearOfLeaving}</em></p>
                          <p>
                          {item.achievements}
                          </p>
                       </div>
                    </div>

                  )
                })
              }
            </div> 
         </div>
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>Expertise</span></h1>
            </div>
            <div className="nine columns main-col">
              {
                resumeData.roles && resumeData.roles.map((item) => {
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                          <p className="info">
                          <span>&bull;</span> <em className="date">{item.rolename}</em></p>
                       </div>
                    </div>
                  )
                })
              }
            </div> 
         </div>
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>Speaker Highlights</span></h1>
            </div>
            <div className="nine columns main-col">
              {
                resumeData.speakers && resumeData.speakers.map((item) => {
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                          <p className="info">
                          <span>&bull;</span> <em className="date">{item.speaker}</em></p>
                       </div>
                    </div>
                  )
                })
              }
            </div> 
         </div>
         <div className="row skill">
            <div className="three columns header-col">
               <h1><span>Programming</span></h1>
            </div>
            <div className="nine columns main-col">
              <div className="bars">
                <ul className="skills">
                    {
                      resumeData.skills && resumeData.skills.map((item) => {
                        return(
                          <li>
                          <span className={`bar-expand${item.value}`}>
                          </span><em>{item.skillname}</em>
                          </li>
                        )
                      })
                    }
                </ul>
              </div>
   			    </div>
        </div>
         <div className="row skill">
            <div className="three columns header-col">
               <h1><span>Technologies</span></h1>
            </div>
            <div className="nine columns main-col">
              <div className="bars">
                <ul className="skills">
                    {
                      resumeData.technologies && resumeData.technologies.map((item) => {
                        return(
                          <li>
                          <span className={`bar-expand${item.value}`}>
                          </span><em>{item.techname}</em>
                          </li>
                        )
                      })
                    }
                </ul>
              </div>
   			    </div>
        </div>
      </section>
    );
  }
}