import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <h4><b>Connect With Me</b></h4>
              <h4><a href={resumeData.mailto} target="_blank" rel="noopener noreferrer">{resumeData.email}</a></h4>
              <h4><a href={resumeData.whatsapp} target="_blank" rel="noopener noreferrer">{resumeData.phoneNumber}</a></h4>
            </div>
          </div>
        </section>
        );
  }
}