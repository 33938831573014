let resumeData = {
    "imagebaseurl":"https://bayutridewanto.github.io/",
    "name": "Bayu Dewanto",
    "role": "Technical Solution Strategic with 10 years experience based in Jakarta Indonesia",
    "linkedinId":"bayutridewanto",
    "skypeid": "bayutridewanto",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/bayutridewanto/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/bayutridewanto",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"http://twitter.com/bayutridewanto  ",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme1":"As a Technical Solutions Architect with 10 years of experience.",
    "aboutme2":"Self-initiative, responsible, hardworking, trustworthy, disciplined, loyal and thirst for skill and self-development.",
    "aboutme3":"I have a deep understanding of the latest technologies and how to apply them to solve complex business problems. I am passionate about creating innovative solutions and driving digital transformation. I believe that technology should be used to improve people's lives and make the world a better place.",
    "age":"Age: 32 Years",
    "nationality":"Nationality: Indonesian",
    "freelance":"Freelance: Available",
    "languages":"Languages: English, Indonesian",
    "address":"Address: Jakarta, Indonesia",
    "email":"Email: bayu@bayudewanto.com",
    "mailto":"mailto:bayu@bayudewanto.com",
    "phoneNumber":"Phone Number: +62 8111-743-447",
    "whatsapp":"https://wa.me/628111743447",
    "website":"Website: https://bayudewanto.com",
    "education":[
      {
        "universityName":"UGM",
        "specialization":"Information Technology",
        "monthOfPassing":"Sep",
        "yearOfPassing":"2013",
        "achievements":"3,33 GPA"
      }
    ],
    "work":[
      {
        "companyName":"PT. Telekomunikasi Selular",
        "specialization":"Digital Channel Technical Strategic",
        "monthOfLeaving":"",
        "yearOfLeaving":"Present",
        "achievements":"My Telkomsel, My Orbit, Telkomsel Web e-Commerce, Ilmupedia"
      },
      {
        "companyName":"PT. Astra Graphia Information Technology",
        "specialization":"Section Head of Development Center",
        "monthOfLeaving":"Jul",
        "yearOfLeaving":"2021",
        "achievements":"Platinum Award Best Project Leader, PT. Astra Graphia Information Technology (2020), Platinum Award Best Consultant Analyst, PT. Astra Graphia Information Technology (2019)"
      }
    ],
    "roles":[
      {
        "rolename":"Technical Solution Architect"
      },
      {
        "rolename":"Enterprise Integrations"
      },
      {
        "rolename":"Automation Architect"
      },
      {
        "rolename":"Cloud Enterprise Architect"
      },
      {
        "rolename":"System and Middleware Engineer"
      }
    ],
    "speakers":[
      {
        "speaker":"Developer Conference 2021: Accelerating Indonesia's Digital Economy - Microsoft"
      },
      {
        "speaker":"Webinar: Transform with DevOps - PT. Astra Graphia Information Technology (AGIT)"
      },
      {
        "speaker":"Webinar: What's DevSecOps - PT. United Tractors Tbk (UT)"
      },
      {
        "speaker":"Forum Sharing: Transform with DevSecOps - LKPP"
      }
    ],
    "skills":[
      {
        "skillname":"Golang",
        "value":"100"
      },
      {
        "skillname":"Java",
        "value":"100"
      },
      {
        "skillname":"Python",
        "value":"80"
      },
      {
        "skillname":"Node JS",
        "value":"90"
      },
      {
        "skillname":"React JS",
        "value":"80"
      }
    ],
    "technologies":[
      {
        "techname":"Docker",
        "value":"90"
      },
      {
        "techname":"Vagrant",
        "value":"70"
      },
      {
        "techname":"Kubernetes",
        "value":"100"
      },
      {
        "techname":"DevSecOps",
        "value":"100"
      }
    ],
    "portfolio1":[
      {
        "name":"My Telkomsel - Dashboard",
        "description":"Home Personalization",
        "imgurl":"images/portfolio/mytelkomsel/mytsel1.webp"
      },
      {
        "name":"My Telkomsel - One",
        "description":"Supports Indihome",
        "imgurl":"images/portfolio/mytelkomsel/mytsel2.webp"
      },
      {
        "name":"My Telkomsel - History Transaction",
        "description":"Usage Transparency",  
        "imgurl":"images/portfolio/mytelkomsel/mytsel3.webp"
      },
      {
        "name":"My Telkomsel - MyVoucher",
        "description":"MyVoucher Discount",
        "imgurl":"images/portfolio/mytelkomsel/mytsel4.webp"
      }
    ],
    "portfolio2":[
      {
        "name":"My Orbit - Store",
        "description":"Buy Package",
        "imgurl":"images/portfolio/myorbit/myorbit1.webp"
      },
      {
        "name":"My Orbit - Dashboard",
        "description":"Home Dashboard",
        "imgurl":"images/portfolio/myorbit/myorbit2.webp"
      },
      {
        "name":"My Orbit - Usage",
        "description":"Usage Statistics",  
        "imgurl":"images/portfolio/myorbit/myorbit3.webp"
      },
      {
        "name":"My Orbit - Connected Device",
        "description":"Modem Features",
        "imgurl":"images/portfolio/myorbit/myorbit4.webp"
      }
    ],
    "testimonials":[
      {
        "description":"My perfect leaders hehe",
        "name":"Bayu Hendra Setiawan (Fullstack Developer)"
      },
      {
        "description":"My role model, stay humble mas...",
        "name":"Yudi Eka Putra (Backend Developer Sr. Specialist & DevOps Engineer)"
      },
      {
        "description":"Technical partner guy :)",
        "name":"Ferbianto (Elevating Software Development)"
      },
      {
        "description":"Waiting for the next sharing, bro",
        "name":"Ricky Haryadi (Strategic Partnership Corporate - Microsoft)"
      }
    ]
  }
  
  export default resumeData