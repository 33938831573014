import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic"  src="images/profilepic.png" alt="" />
            </div>
            <div className="nine columns main-col">
               <h2>About Me</h2>
               <p>{resumeData.aboutme1}</p>
               <p>{resumeData.aboutme2}</p>
               <p>{resumeData.aboutme3}</p>
               <div className="row">
                  <div className="columns contact-details">
                  <h2>Contact Details</h2>
                     <p className="address">
                        <span>{resumeData.age}</span>
                        <br></br>
                        <span>{resumeData.nationality}</span>
                        <br></br>
                        <span>{resumeData.freelance}</span>
                        <br></br>
                        <span>{resumeData.languages}</span>
                        <br></br>
                        <span>{resumeData.address}</span>
                     </p>
                  </div>
               </div>
               <h2>My Resume</h2>
               <p><a href="files/cv.pdf" target="_blank">download</a></p>
            </div>
         </div>
      </section>
    );
  }
}